import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const TestimonialStyles = styled.div`
  display: flex;
  padding: 1em 3em;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: 1em auto;
  width: fit-content;

  .customer {
    .img {
      height: 150px;
      width: 150px;

      .gatsby-image-wrapper {
        border-radius: 50%;
      }
    }

    .name {
      text-align: center;
      font-weight: 700;
    }
  }

  .testimony {
    padding: 0 2em 2em;
    .rating {
      color: #FFCD01;
      font-size: 2em;
    }

    p {
      font-size: 0.8em;
      max-width: 600px;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .customer {
      .name {
        margin-bottom: 0;
      }
    }

    .testimony {
      padding: 0;

      .rating {
        text-align: center;
      }
    }
  }
`

export default function Testimonial({ testimonial }) {
  const ratingStars = []

  for (let i = 0; i < testimonial?.rating; i++) {
    ratingStars.push(<span key={i + 1}>&#9733;</span>)
  }

  return (
    <TestimonialStyles>
      <div className="customer">
        <div className="img">
          <GatsbyImage
            image={testimonial.image.childImageSharp.gatsbyImageData}
            alt={testimonial.name}
            imgStyle={{objectFit: 'contain'}} />
        </div>
        <p className="name">{ testimonial?.name }</p>
      </div>
      <div className="testimony">
        <div className="rating">
          {ratingStars}
        </div>
        <p>{ testimonial?.testimony }</p>
      </div>
    </TestimonialStyles>
  )
}