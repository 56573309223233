import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonial from "../components/testimonial"

const TestimonialsPageStyles = styled.div`
  h1 {
    font-family: "hand-of-sean", Arial, Helvetica, sans-serif;
    color: #000;
    margin: 0;
    padding-top: 1em;
    text-align: center;
  }
`

export default function TestimonialsPage({ data }) {
  const siteTitle = "Testimonials"
  const testimonials = data.allFile.nodes

  return (
    <Layout>
      <SEO
        title={siteTitle}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <TestimonialsPageStyles>
        <h1>Testimonials</h1>
        {testimonials.map((item, index) => {
          const testimonial = item.childMdx.frontmatter
          return <Testimonial key={index + 1} testimonial={testimonial} />
        })}
      </TestimonialsPageStyles>
    </Layout>
  )
}

export const query = graphql`
  query Testimonials {
    allFile(filter: { sourceInstanceName: { eq: "testimonials" } }) {
      nodes {
        name
        childMdx {
          id
          frontmatter {
            path
            name
            testimony
            rating
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  height: 150
                  width: 150
                  layout: FIXED
                )
              }
            }
          }
        }
      }
    }
  }
`
